<template>
  <div class="padrao">
    <section class="img-painel-superior">
      <div class="container">
        <div class="imagem-superior">
          <img class="imagem-logo-superior" src="/img/logo-marca.png" />
        </div>
        <div class="row painel-superior-padding">
          <div class="col-sm-12 col-md-7 col-lg-7 selo-seguranca-pedding" style="align-self:center">
            <div class="estilo-titulo-primeiro">
              Selo de segurança
            </div>
            <div class="estilo-titulo-primeiro-sub">
              Bike Registrada
            </div>
            <div class="estilo-titulo-segundo">
              Faça todo mundo saber que a sua bike é sua! <br />
            </div>
            <div class="mb-2 esconde-botao">
              <a class="btn btn-primeiro" href="#facaseupedido" id="4">
                <div class="titulo-btn-primeiro">
                  Quero me proteger agora
                </div>
              </a>
            </div>
            <div class="esconde-botao">
              <button class="btn btn-segundo" @click.prevent="meuSmoth()">
                <a href="#primeiraSessao" style="color: #45b400;text-decoration:none;">Saiba mais</a>
              </button>
            </div>
          </div>
          <div class="col-sm-12 col-md-5 col-lg-5 mb-4 padding-retangulo-painel-superior">
            <div class="retangolo-selo">
              <div>
                <div class="mb-2">
                  <img class="img-selo-painel-superior-retangulo-verde" src="/img/logo-selo.5a75c2d6.png" />
                </div>
                <div>
                  <img class="img-selo-painel-superior-retangulo-qrcode" src="/img/qrcode-selo.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-2 mostra-botao">
          <a class="btn btn-block btn-primeiro-mobile " href="#facaseupedido" id="5">
            <div class="titulo-btn-primeiro-2">
              Quero me proteger agora
            </div>
          </a>
        </div>
        <div class="mostra-botao  mb-3 ">
          <button class="btn  btn-block btn-segundo-mobile">
            <a href="#primeiraSessao" style="color: #45b400;">Saiba mais</a>
          </button>
        </div>
      </div>
    </section>

    <section class="container" id="primeiraSessao">
      <div class="titulo-principal" style="">
        A melhor escolha para sua
        <span class="titulo-principal-sub">segurança</span> e da sua bike
      </div>
      <div class="row">
        <div class="esconde-botao col-sm-12 col-md-3 col-lg-4 ">
          <div class="retangulo-painel-superior">
            <div class="mb-2">
              <img class="img-selo-retangulo-verde" src="/img/logo-selo.5a75c2d6.png" />
            </div>
            <div>
              <img class="img-selo-retangulo-qrcode" src="/img/qrcode-selo.png" />
            </div>
          </div>
        </div>
        <div class=" col-sm-12 col-md-9 col-lg-8">
          <p class="titulo-principal-sub-1">
            Veja os benefícios de ter a sua bike registrada e com SELO.
          </p>

          <p class="titulo-principal-sub-2">
            <img src="/img/img-check.png" class="img-check-segunda-section" />
            Combate ao comércio de bicicletas roubadas/furtadas
          </p>
          <p class="titulo-principal-sub-2">
            <img src="/img/img-check.png" class="img-check-segunda-section" />
            Facilidade e praticidade na identificação da bike e do proprietário
          </p>
          <p class="titulo-principal-sub-2">
            <img src="/img/img-check.png" class="img-check-segunda-section" />
            Reais chances de recuperação de sua bike, caso seja roubada/furtada
          </p>
          <p class="titulo-principal-sub-2">
            <img src="/img/img-check.png" class="img-check-segunda-section" />
            Combate a ação dos ladrões
          </p>
          <p class="titulo-principal-sub-2">
            <img src="/img/img-check.png" class="img-check-segunda-section" />
            Redução do risco da compra de bicicletas roubadas
          </p>
          <p class="titulo-principal-sub-2">
            <img src="/img/img-check.png" class="img-check-segunda-section" />
            Notificação com a localização de quem consultar sua bicicleta
          </p>
          <p class="titulo-principal-sub-2">
            <img src="/img/img-check.png" class="img-check-segunda-section" />
            Informações vitais do ciclista e contato de emergência em caso de
            acidente
          </p>
          <p class="titulo-principal-sub-2">
            Aproveite agora todas as vantagens que o Selo de Segurança Bike
            Registrada oferece!
          </p>

          <div class="text-center mt-4">
            <a class="btn btn-sub-topico" href="#facaseupedido" id="1">
              <div class="titulo-btn-primeiro-2">
                Quero me proteger agora
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
    <section class="container">
      <div class="terceira-section">
        <div class="mostra-botao terceira-section-titulo">
          Com o
          <span class="titulo-principal-sub">Bike Loc </span>
          você pode saber onde sua bike foi vista pela ultima vez
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-8 col-lg-7">
            <div class="mostra-botao col-sm-12 col-md-4 col-lg-5">
              <div class="imagem-terceira-position">
                <img class="imagem-terceira-section" src="/img/maps-bike.png" />
              </div>
            </div>
            <div class="esconde-botao terceira-section-titulo">
              Com o
              <span class="titulo-principal-sub">Bike Loc </span>
              você pode saber onde sua bike foi vista pela ultima vez
            </div>
            <div class="titulo-principal-sub-2">
              <p>
                O serviço é bem simples,
                <b>quando alguém consultar uma bicicleta com o status de
                  roubada</b>/furtada acionado e que possuir o Selo de Segurança vinculado,
                o dono receberá um SMS com a localização aproximada de onde a
                consulta foi realizada.
              </p>
              <p>
                Para ter acesso ao serviço basta possuir uma bicicleta
                registrada com o Selo de Segurança Bike Registrada ativado! Após
                vincular o
                <b class="titulo-principal-sub">Selo o BIKE LOC</b> será ativado
                automaticamente.
              </p>
              <b>Caso o selo seja removido da bike</b>, o BIKE LOC continuará
              funcionando para consultas utilizando o número de série da
              bicicleta.
            </div>
            <div class="text-center mt-4">
              <a class="btn btn-sub-topico" href="#facaseupedido" id="2">
                <div class="titulo-btn-primeiro-2">
                  Quero me proteger agora
                </div>
              </a>
            </div>
          </div>
          <div class="esconde-botao col-sm-12 col-md-4 col-lg-5">
            <div>
              <img class="imagem-terceira-section" src="/img/maps-bike.png" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container " style="padding-top:60px">
      <div class="mostra-botao quarta-section-titulo">
        O Bike Vida garante a
        <span class="titulo-principal-sub"> sua segurança</span>
        também
      </div>
      <div class="row">
        <div class=" col-sm-12 col-md-5 col-lg-5  " style="align-self: end;">
          <div class="imagem-terceira-position">
            <img class="img-quarta-section" src="/img/celular-bike-vida.png" />
          </div>
        </div>
        <div class="col-sm-12 col-md-7 col-lg-7">
          <div class="esconde-botao quarta-section-titulo">
            O Bike Vida garante a
            <span class="titulo-principal-sub"> sua segurança</span>
            também
          </div>
          <p class="titulo-principal-sub-2">
            O Bike Vida é um serviço disponível no Selo de Segurança Bike
            Registrada que permite
            <b> aconsulta das informações vitais de um ciclista</b> e ainda
            <b> fornece contatos de emergência </b>que poderão ser acionados em
            caso de acidentes.
          </p>
          <div class="padding-mobile">
            <p class="titulo-principal-sub-2">
              <img src="/img/img-healph-check.png" class="img-check-segunda-section" />Identificação do Ciclista
            </p>
            <p class="titulo-principal-sub-2">
              <img src="/img/img-healph-check.png" class="img-check-segunda-section" />Contato de Emergência
            </p>
            <p class="titulo-principal-sub-2">
              <img src="/img/img-healph-check.png" class="img-check-segunda-section" />Ficha Médica
            </p>
            <p class="titulo-principal-sub-2">
              <img src="/img/img-healph-check.png" class="img-check-segunda-section" />Tipo Sanguíneo
            </p>
            <p class="titulo-principal-sub-2">
              <img src="/img/img-healph-check.png" class="img-check-segunda-section" />Alergias
            </p>
            <p class="titulo-principal-sub-2">
              <img src="/img/img-healph-check.png" class="img-check-segunda-section" />Problemas de saúde
            </p>
            <p class="titulo-principal-sub-2">
              <img src="/img/img-healph-check.png" class="img-check-segunda-section" />Medicamentos e muito mais...
            </p>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="text-center mt-4">
                <a class="btn btn-sub-topico" href="#facaseupedido" id="3">
                  <div class="titulo-btn-primeiro-2">
                    Quero me proteger agora
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container quinta-section-1">
      <div class="container quinta-section">
        Nosso aplicativo está disponível para Android e iOS. Faça o download
        agora para começar!
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-6">
          <div class="img-quinta-section">
            <img class="img-quinta-section-google" src="/img/loja-google.png" />
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
          <div class="img-quinta-section-1">
            <img class="img-quinta-section-apple" src="/img/loja-apple.png" />
          </div>
        </div>
      </div>
    </section>

    <section class="sexta-section-1">
      <div class="sexta-section-retangulo">
        <div class="sexta-section">
          <div class="row" style="
                                  margin-left: 0px;
                                  margin-right: 0px;
                                ">
            <div class="col-sm-12 col-md-7 col-lg-7">
              <div class="sexta-section-titulo">
                Comunidade Bike Registrada!
              </div>
              <div class="sexta-section-subtitulo">
                <p>Milhares de ciclistas pelo Brasil já confiam na Bike Registrada! </p>
                <p>Venha fazer parte da maior comunidade de ciclistas do Brasil, só falta você!</p>
                <p>O selo pode ser colado no quadro da bicicleta, pois além de trazer as informações do ciclista e da
                  bike, também funciona como um localizador da sua bike.</p>
              </div>
            </div>
            <div class="col-sm-12 col-md-5 col-lg-5">
              <iframe class="sexta-section-img" src="https://www.youtube.com/embed/CIrllVqNiMI"
                title="YouTube video player" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen="">

              </iframe>
            </div>
          </div>
        </div>
      </div>
      <div class="row sexta-section-retangulo-preto" style="
                                  margin-left: 0px;
                                  margin-right: 0px;
                                ">
        <div class="container">
          <div class="col-12 sexta-etapa-nossos-numeros ">
            Nossos números
            <div class="row mt-5 ">
              <div class="col-sm-12 col-md-4 col-lg-4 sexta-etapa-nossos-numeros-1">
                <div>
                  450.000 +
                </div>
                <div class="sexta-etapa-nossos-numeros-sub">
                  <div>
                    Somos mais de 450 mil ciclistas com a bike registrada
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-4 col-lg-4 sexta-etapa-nossos-numeros-1">
                9.678
                <div class="sexta-etapa-nossos-numeros-sub-2">
                  Alertas de roubo
                </div>
              </div>
              <div class="col-sm-12 col-md-4 col-lg-4 sexta-etapa-nossos-numeros-1">
                33%
                <div class="sexta-etapa-nossos-numeros-sub-3">
                  Taxa de recuperação
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="setima-section">
      <div class="container setima-section-titulo">
        O que nossos clientes <br />
        dizem sobre o nosso selo?
      </div>
      <Flicking :options="{ duration: 2500, horizontal: true, circular: true }" :plugins="plugins">
        <div class="panel setima-section-img-caroseu">
          <img class="setima-section-img1-caroseu " src="/img/balao-depoimento.svg" />
          <img class="setima-section-img2-caroseu" src="/img/balao-depoimento.png" />
          <p class="setima-section-img3-caroseu">
            Muito bom mesmo!!! Roubaram minha bike na praça da Sé em São Paulo e
            através desse aplicativo eu encontrei.
          </p>
          <p class="setima-section-img4-caroseu">
            Valter Resende
          </p>
        </div>
        <div class="panel setima-section-img-caroseu">
          <img class="setima-section-img1-caroseu " src="/img/balao-depoimento-2.svg" />
          <img class="setima-section-img2-caroseu" src="/img/balao-depoimento.png" />
          <p class="setima-section-img3-caroseu">
            Maneira muito inteligente e prática de identificação e localização
            das bicicletas
          </p>
          <p class="setima-section-img4-caroseu">
            Roberto Marnet
          </p>
        </div>
        <div class="panel setima-section-img-caroseu">
          <img class="setima-section-img1-caroseu " src="/img/balao-depoimento-3.svg" />
          <img class="setima-section-img2-caroseu" src="/img/balao-depoimento.png" />
          <p class="setima-section-img3-caroseu">
            Excelente iniciativa. Uma camada a mais de segurança para os
            ciclistas, muito bem vinda.
          </p>
          <p class="setima-section-img4-caroseu">
            Sérgio Costa de Oliveira
          </p>
        </div>
        <div class="panel setima-section-img-caroseu">
          <img class="setima-section-img1-caroseu " src="/img/balao-depoimento-4.svg" />
          <img class="setima-section-img2-caroseu" src="/img/balao-depoimento.png" />
          <p class="setima-section-img3-caroseu">
            Eu uso e recomendo.
          </p>
          <p class="setima-section-img4-caroseu">
            Raphael Silva
          </p>
        </div>
        <div class="panel setima-section-img-caroseu">
          <img class="setima-section-img1-caroseu " src="/img/balao-depoimento-5.svg" />
          <img class="setima-section-img2-caroseu" src="/img/balao-depoimento.png" />
          <p class="setima-section-img3-caroseu">
            Gostei muito indico para pessoas que pedalam.
          </p>
          <p class="setima-section-img4-caroseu">
            José Wilson
          </p>
        </div>
        <div class="panel setima-section-img-caroseu">
          <img class="setima-section-img1-caroseu " src="/img/balao-depoimento-6.svg" />
          <img class="setima-section-img2-caroseu" src="/img/balao-depoimento.png" />
          <p class="setima-section-img3-caroseu">
            Serviço TOP! Comprei meu SELO e chegou em 7 dias!!!
          </p>
          <p class="setima-section-img4-caroseu">
            Izabela Souza
          </p>
        </div>
        <div class="panel setima-section-img-caroseu">
          <img class="setima-section-img1-caroseu " src="/img/balao-depoimento-7.svg" />
          <img class="setima-section-img2-caroseu" src="/img/balao-depoimento.png" />
          <p class="setima-section-img3-caroseu">
            -Top! muito legal mesmo! fácil aplicação! refletiva! E ainda através
            do site bike registrada, fiz um seguro para a bike com valor muito
            legal. Vale muito a pena! recomendo!
          </p>
          <p class="setima-section-img4-caroseu">
            Avaliação no Mercado Livre
          </p>
        </div>
        <span slot="viewport" class="flicking-arrow-prev "></span>
        <span slot="viewport" class="flicking-arrow-next"></span>
        <div slot="viewport" class="flicking-pagination"></div>
      </Flicking>
    </section>

    <section id="facaseupedido" class="oitava-section">
      <p class="oitava-section-titulo">
        Faça seu pedido agora
      </p>
      <div class="oitava-section-card">
        <div class="row">
          <div class="col-sm-12 col-md-3 col-lg-3">
            <div class="mostra-botao">
              <p class="oitava-section-retangulo-titulo">
                Selo Bike Registrada
              </p>
              <p class="oitava-section-retangulo-subtitulo">
                Selo para aplicação no quadro da sua bike
              </p>
            </div>
            <div class="imagem-terceira-position">
              <div class="oitava-section-retangulo">
                <div class="mb-2">
                  <img class="oitava-section-retangulo-logo" src="/img/logo-selo.5a75c2d6.png" />
                </div>
                <div>
                  <img class="oitava-section-retangulo-qrcode" src="/img/qrcode-selo.png" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-5 col-lg-5">
            <div class="esconde-botao">
              <p class="oitava-section-retangulo-titulo">
                Selo Bike Registrada
              </p>
              <p class="oitava-section-retangulo-subtitulo">
                Selo para aplicação no quadro da sua bike <br />
              </p>
            </div>
            <p class="mostra-botao oitava-section-retangulo-subtitulo-1 mt-5 ">
              Quantidade:
            </p>
            <div>
              <b-button-group>
                <b-button style="box-shadow: none;" variant="light" class="bottom-final"
                  @click.prevent="removerQtdSelo()">-</b-button>
                <b-button style="box-shadow: none;" variant="light" class="bottom-final">{{ this.qtdSelo }}</b-button>
                <b-button style="box-shadow: none;" variant="light" class="bottom-final"
                  @click.prevent="adicionarQtdSelo()">+
                </b-button>
              </b-button-group>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 col-lg-4">
            <div class="row">
              <div class="esconde-linha col-md-2 col-lg-2 box-line">
                <hr class="line3" />
              </div>
              <div class="col-sm-12 col-md-10 col-lg-10 mt-3">
                <div class="row">
                  <div class="col-6 estilo-pedido">Preço</div>
                  <div class="col-6 estilo-preco-pedido">
                    R$ {{ config.VALOR_SELO.toFixed(2).replace(".", ",") }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 estilo-pedido">Frete</div>
                  <div class="col-6 estilo-preco-pedido">{{ config.VALOR_FRETE.toFixed(2).replace(".", ",") }}</div>
                </div>
                <hr style="border: 1px solid #A3A3A3;" />
                <div class="row">
                  <div class="col-6 estilo-pedido">Total</div>
                  <div class="col-6 estilo-preco-pedido">
                    R$
                    {{
                      this.valorTotalSelo
                    }}
                  </div>
                </div>
                <div class="text-center mt-4">
                  <b-button variant="success" class="btn btn-block">
                    <div class="titulo-btn-primeiro" @click.prevent="irParaCheckoutSelo()">
                      Comprar agora
                    </div>
                  </b-button>
                  <span>
                    *Frete único para todo Brasil!
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <RodapeSelo :smoothScroll="true" :menuHeight="menuHeight"></RodapeSelo>
  </div>
</template>
<script>
import config from "../../config";
import { Flicking } from "@egjs/vue-flicking";
import { Pagination, Arrow, AutoPlay } from "@egjs/flicking-plugins";
import "@egjs/flicking-plugins/dist/pagination.css";
import "@egjs/flicking-plugins/dist/arrow.css";
import RodapeSelo from "../../components/RodapeSelo";
import helper from "../../helpers/helper";
export default {
  components: {
    Flicking: Flicking,
    RodapeSelo,
  },
  data() {
    return {
      config,
      helper,
      qtdSelo: 1,
      valorTotalSelo: undefined, menuHeight: 0,
      plugins: [new Pagination({ type: 'bullet' }), new Arrow({ parentEl: document.body }), new AutoPlay(5000, "NEXT")],
    };
  },
  created() {
    this.valorTotalSelo = (config.VALOR_FRETE + config.VALOR_SELO).toFixed(2).replace(".", ",")
  },
  methods: {
    meuSmoth() {
      window.scrollTo(0, 540);
    },

    gravarCarrinho() {
      this.helper.setLocalStorage("__CARRINHO_SELO", this.qtdSelo);
    },
    irParaCheckoutSelo() {
      this.gravarCarrinho();
      this.$router.replace("/selo/checkout-dados");
    },
    adicionarQtdSelo() {
      this.qtdSelo++;
      this.valorTotalSelo = (config.VALOR_SELO * this.qtdSelo + config.VALOR_FRETE)
        .toFixed(2)
        .replace(".", ",");
    },
    removerQtdSelo() {
      if (this.qtdSelo > 1) {
        this.qtdSelo--;
        this.valorTotalSelo = (config.VALOR_SELO * this.qtdSelo + config.VALOR_FRETE)
          .toFixed(2)
          .replace(".", ",");
      }
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Roboto:400,700,500,600|Archivo:400,700,900,300,500,600");

.padrao {
  font-family: Archivo;
  font-style: normal;
}

.img-painel-superior {
  background-image: url("/img/img-fundo.svg");
  height: 100%;
  background-position: 70%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.esconde-botao {
  display: block;
}

.esconde-linha {
  display: block;
}

.mostra-botao {
  display: none;
}

.imagem-superior {
  padding-top: 50px;
  padding-bottom: 30px;
  padding-left: 98px;
}

.imagem-logo-superior {
  width: 349px;
}

.selo-seguranca-pedding {
  padding-left: 107px;
}

.estilo-titulo-primeiro {
  font-weight: 700;
  margin-bottom: -19px;
  margin-top: 50px;
  font-size: 50px;
  color: rgb(255, 255, 255);
}

.estilo-titulo-primeiro-sub {
  color: rgb(69, 180, 0);
  font-weight: 700;
  font-size: 50px;
  line-height: 54px;
}

.estilo-titulo-segundo {
  font-weight: 400;
  margin-bottom: 10px;
  font-size: 25px;
  color: rgb(216, 216, 216);
}

.btn-segundo {
  padding: 5px;
  background: #222222;
  border-radius: 4px;
  width: 428px;
  border-color: #45b400;
  color: #45b400;
}

.btn-primeiro {
  background: #45b400;
  width: 428px;
  border-radius: 4px;
  padding: 5px;
  border-color: #45b400;
}

.img-selo-painel-superior-retangulo-verde {
  width: 70%;
  padding-top: 20px;
}

.img-selo-painel-superior-retangulo-qrcode {
  width: 70%;
}

.painel-superior-padding {
  padding-bottom: 60px;
}

.retangolo-selo {
  text-align: center;
  width: 39%;
  height: 100%;
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 23px;
}

.titulo-btn-primeiro {
  font-family: "Roboto";
  font-weight: 400;
  color: #ffffff;
}

.titulo-btn-primeiro-2 {
  font-family: "Roboto";
  font-weight: 400;
  color: #ffffff;
  font-size: 22px;
}

/* ===============Fim estilo primeira Seção ======================== */

.titulo-principal-sub {
  color: #45b400;
}

.titulo-principal {
  font-family: "Archivo";
  font-weight: 600;
  font-size: 36px;
  text-align: center;
  color: #222222;
  padding-top: 50px;
  padding-bottom: 30px;
}

.img-qrcode-selo {
  position: absolute;
  width: 171.62px;
  height: 171.62px;
  left: 31px;
  top: 302px;
}

.img-selo {
  position: absolute;
  width: 178.32px;
  height: 296.31px;
  left: 31px;
  top: 3.45px;
  background: url("/img/logo-selo.png");
}

.retangulo-painel-superior {
  text-align: center;
  position: relative;
  width: 50%;
  height: 80%;
  background: #ffffff;
  border-radius: 8px;
  left: 94px;
  box-shadow: 3px 3px 50px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.img-selo-retangulo-verde {
  width: 65%;
  padding-top: 20px;
}

.img-selo-retangulo-qrcode {
  width: 70%;
}

.titulo-principal-sub-1 {
  font-family: "Roboto";

  font-weight: 400;
  font-size: 20px;
  color: #222222;
}

.img-check-segunda-section {
  margin-right: 10px;
}

.titulo-principal-sub-2 {
  font-family: Roboto;

  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: rgb(34, 34, 34);
}

.btn-sub-topico {
  background: #45b400;
  border-radius: 4px;
}

/* ===============Fim estilo segunda Seção ======================== */
.terceira-section {
  padding-top: 120px;
  padding-bottom: 0px;
  padding-left: 83px;
}

.imagem-terceira-section {
  width: 222px;
}

.terceira-section-titulo {
  font-weight: 600;
  font-size: 30px;
  text-align: center;
  margin-bottom: 25px;
  color: rgb(34, 34, 34);
}

.terceira-section-sub {
  position: relative;
  font-family: Roboto;

  font-weight: 400;
  font-size: 15px;
  color: rgb(34, 34, 34);
}

/* ===============Fim estilo terceira Seção ======================== */

.quarta-section-titulo {
  font-weight: 600;
  font-size: 30px;
  text-align: left;
  margin-bottom: 25px;
  color: rgb(34, 34, 34);
}

.quarta-section-titulo-sub {
  font-size: 15px;
}

.img-quarta-section {
  width: 439px;
  height: 450px;
}

/* ===============Fim estilo quarta Seção ======================== */
.quinta-section {
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: #000000;
}

.quinta-section-1 {
  padding-top: 100px;
  padding-bottom: 130px;
  padding-left: 153px;
}

.img-quinta-section {
  padding-top: 12px;
  text-align: center;
  margin-left: 107px;
}

.img-quinta-section-1 {
  padding-top: 12px;
  text-align: left;
  margin-left: 50px;
}

.img-quinta-section-google {
  width: 130px;
}

.img-quinta-section-apple {
  width: 130px;
}

/* ===============Fim estilo quinta Seção ======================== */
.sexta-section-titulo {
  font-family: "Archivo";

  font-weight: 600;
  font-size: 36px;
  color: #ffffff;
  padding-top: 35px;
  line-height: 40px;
}

.sexta-section-subtitulo {
  font-family: Roboto;

  font-weight: 400;
  font-size: 18px;
  color: rgb(224, 254, 214);
  padding-top: 26px;
  padding-bottom: 26px;
}

.sexta-section-img {
  width: 100%;
  height: 100%;
  padding-top: 48px;
}

.sexta-section-retangulo {
  position: absolute;
  margin-top: -100px;
  width: 100%;
  padding-right: 145px;
  padding-left: 85px;
}

.sexta-section {
  background: linear-gradient(90.7deg,
      rgb(69, 180, 0) 1.76%,
      rgb(62, 163, 0) 96.3%);
  position: relative;
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 25px;
  margin-right: 25px;
  margin-left: 25px;
}

.sexta-section-1 {
  margin-top: 104px;
}

.sexta-etapa-nossos-numeros {
  font-weight: 700;
  font-size: 35px;
  text-align: center;
  color: rgb(69, 180, 0);
  margin-top: 350px;
}

.sexta-section-retangulo-preto {
  background: linear-gradient(124.16deg, #222222 18.46%, #131414 83.41%);
}

.sexta-etapa-nossos-numeros-1 {
  font-size: 48px;
  color: rgb(255, 255, 255);

  font-weight: 900;
  text-align: center;
}

.sexta-etapa-nossos-numeros-sub {
  color: rgb(216, 216, 216);
  font-size: 14px;
  font-family: "Roboto";
  text-align: center;

  font-weight: 400;
}


.sexta-etapa-nossos-numeros-sub-2 {
  color: rgb(216, 216, 216);
  font-size: 14px;
  font-family: "Roboto";
  text-align: center;
  font-weight: 400;
}

.sexta-etapa-nossos-numeros-sub-3 {
  color: rgb(216, 216, 216);
  font-size: 15px;
  font-family: "Roboto";
  text-align: center;
  font-weight: 400;
  margin-bottom: 154px;
}

/* ===============Fim estilo sexta Seção ======================== */
.setima-section {
  background: linear-gradient(90.7deg, #45b400 1.76%, #3ea300 96.3%);
}

.setima-section-titulo {
  font-weight: 600;
  font-size: 35px;
  color: rgb(255, 255, 255);
  text-align: center;
  padding-top: 51px;
}

.setima-section-img-caroseu {
  border-radius: 4px;
  margin-left: 0px;
  background: rgb(255, 255, 255);
  margin-top: 16px;
  margin-bottom: 60px;
  margin-right: 20px;
  width: 25%;
}

.setima-section-img1-caroseu {
  margin-top: 32px;
  margin-left: 37%;
}

.setima-section-img2-caroseu {
  margin-top: -181px;
  margin-left: 58%;
}

.setima-section-img3-caroseu {
  text-align: center;
  font-family: Roboto;

  font-weight: 400;
  font-size: 16px;
  color: rgb(34, 34, 34);
  padding-left: 35px;
  padding-right: 35px;
}

.setima-section-img4-caroseu {
  text-align: center;
  color: rgb(34, 34, 34);
  font-family: Roboto;

  font-weight: 600;
  font-size: 20px;
  padding-bottom: 10px;
}

/* ===============Fim estilo setima Seção ======================== */
.oitava-section {
  background: #f4f4f4;
  padding: 10%;
  border-radius: 4px;
}

.oitava-section-titulo {
  font-weight: 500;
  font-size: 40px;
  padding-bottom: 50px;
  color: rgb(34, 34, 34);
}

.oitava-section-card {
  background: rgb(255, 255, 255);
  border-radius: 4px;
  padding: 21px;
}

.oitava-section-retangulo {
  text-align: center;
  width: 40%;
  height: 60%;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 10%) 3px 3px 50px;
}

.oitava-section-retangulo-logo {
  width: 70%;
  padding-top: 8px;
}

.oitava-section-retangulo-qrcode {
  width: 70%;
  padding-bottom: 8px;
}

.oitava-section-retangulo-titulo {
  font-family: "Roboto";

  font-weight: 500;
  font-size: 32px;
  color: #222222;
}

.oitava-section-retangulo-subtitulo {
  font-family: Roboto;

  font-weight: 400;
  font-size: 16px;
  color: rgb(111, 111, 111);
  margin-bottom: 21px;
}

.estilo-preco-pedido {
  font-family: "Roboto";
  font-weight: 700;
  text-align: right;
  font-size: 20px;
  color: #222222;
}

.estilo-pedido {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 20px;
  text-align: left;
  color: #a3a3a3;
}

.bottom-final {
  box-sizing: border-box;
  width: 47px;
  height: 45.32px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(186, 186, 186);
}

.line3 {
  background: #a3a3a3;
  height: 100%;
  width: 2px;
  padding: 0px;
  margin: 0 auto;
}

.box-line {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1199px) {
  .imagem-superior {
    padding-top: 50px;
    padding-left: 55px;
  }

  .imagem-logo-superior {
    width: 280px;
  }

  .selo-seguranca-pedding {
    padding-left: 65px;
  }

  .estilo-titulo-primeiro {
    font-size: 40px;
  }

  .estilo-titulo-primeiro-sub {
    font-size: 40px;
  }

  .estilo-titulo-segundo {
    font-size: 22px;
  }

  .btn-segundo {
    width: 368px;
    font-size: 15px;
  }

  .btn-primeiro {
    width: 368px;
    font-size: 15px;
  }

  .img-selo-painel-superior-retangulo-verde {
    padding-top: 10px;
    width: 68%;
  }

  .img-selo-painel-superior-retangulo-qrcode {
    width: 68%;
  }

  /* ===============Fim estilo primeira Seção ======================== */
  .titulo-principal {
    font-size: 29px;
  }

  .titulo-principal-sub-1 {
    font-size: 18px;
  }

  .titulo-principal-sub-2 {
    font-size: 15px;
    line-height: 20px;
  }

  .btn-sub-topico {
    font-size: 12px;
  }

  /* ===============Fim estilo segunda Seção ======================== */
  .imagem-terceira-section {
    width: 180px;
  }

  .terceira-section-titulo {
    font-size: 27px;
  }

  .terceira-section-sub {
    font-size: 12px;
  }

  /* ===============Fim estilo terceira Seção ======================== */
  .quarta-section-titulo {
    font-size: 29px;
    margin-bottom: 25px;
  }

  .quarta-section-titulo-sub {
    font-size: 13px;
  }

  .img-quarta-section {
    width: 339px;
    height: 380px;
  }

  /* ===============Fim estilo quarta Seção ======================== */
  .quinta-section {
    font-size: 16px;
  }

  .img-quinta-section-google {
    width: 130px;
  }

  .img-quinta-section-apple {
    width: 130px;
  }

  .img-quinta-section {
    padding-top: 12px;
    text-align: center;
    margin-left: 148px;
  }

  .img-quinta-section-1 {
    padding-top: 12px;
    text-align: left;
    margin-left: 58px;
  }

  /* ===============Fim estilo quinta Seção ======================== */
  .sexta-section-titulo {
    font-size: 30px;
  }

  .sexta-section-subtitulo {
    font-size: 16px;
  }

  .img-check-segunda-section {
    width: 23px;
    margin-top: -3px;
  }

  /* ===============Fim estilo sexta Seção ======================== */

  .setima-section-img-caroseu {
    width: 35%;
  }
}

@media (max-width: 991px) {
  .imagem-superior {
    padding-left: 20px;
  }

  .imagem-logo-superior {
    width: 200px;
  }

  .selo-seguranca-pedding {
    padding-left: 22px;
  }

  .estilo-titulo-primeiro {
    font-size: 31px;
    margin-top: 30px;
  }

  .estilo-titulo-primeiro-sub {
    font-size: 31px;
  }

  .estilo-titulo-segundo {
    font-size: 16px;
  }

  .btn-segundo {
    width: 270px;
    font-size: 14px;
  }

  .btn-primeiro {
    width: 270px;
    font-size: 14px;
  }

  .img-selo-painel-superior-retangulo-verde {
    padding-top: 10px;
  }

  .img-selo-painel-superior-retangulo-qrcode {
    width: 85%;
  }

  /* ===============Fim estilo primeira Seção ======================== */
  .titulo-principal {
    font-size: 25px;
  }

  .retangulo-painel-superior {
    width: 80%;
    height: 76%;
    left: 14px;
  }

  .img-selo-retangulo-qrcode {
    width: 80%;
  }

  .titulo-principal-sub-1 {
    font-size: 15px;
  }

  .titulo-principal-sub-2 {
    font-size: 12px;
    line-height: 17px;
  }

  .btn-sub-topico {
    font-size: 10px;
  }

  /* ===============Fim estilo segunda Seção ======================== */
  .terceira-section {
    padding-top: 80px;
    padding-left: 03px;
  }

  .imagem-terceira-section {
    width: 170px;
  }

  .terceira-section-titulo {
    font-size: 25px;
  }

  .terceira-section-sub {
    font-size: 12px;
  }

  /* ===============Fim estilo terceira Seção ======================== */
  .quarta-section-titulo {
    font-size: 25px;
    margin-bottom: 25px;
  }

  .quarta-section-titulo-sub {
    font-size: 10px;
  }

  .img-quarta-section {
    width: 299px;
    height: 340px;
  }

  /* ===============Fim estilo quarta Seção ======================== */
  .quinta-section {
    font-size: 12px;
  }

  .img-quinta-section-google {
    width: 130px;
  }

  .img-quinta-section-apple {
    width: 130px;
  }

  .img-quinta-section {
    padding-top: 12px;
    text-align: center;
    margin-left: 80px;
  }

  .img-quinta-section-1 {
    padding-top: 12px;
    text-align: left;
    margin-left: 48px;
  }

  /* ===============Fim estilo quinta Seção ======================== */
  .sexta-section-titulo {
    font-size: 23px;
  }

  .sexta-section-subtitulo {
    font-size: 12px;
  }

  .img-check-segunda-section {
    width: 16px;
    margin-top: -3px;
  }

  .btn-sub-topico {
    font-size: 10px;
  }

  /* ===============Fim estilo quinta Seção ======================== */

  .sexta-section-1 {
    margin-top: 50px;
  }

  .sexta-etapa-nossos-numeros {
    font-weight: 700;
    font-size: 35px;
    margin-top: 320px;
  }

  .sexta-etapa-nossos-numeros-1 {
    font-size: 38px;
    color: rgb(255, 255, 255);
    font-weight: 900;
    text-align: center;
  }

  .sexta-etapa-nossos-numeros-sub {
    color: rgb(216, 216, 216);
    font-size: 10px;
    font-family: "Roboto";
    text-align: center;
    font-weight: 400;
    margin-bottom: 144px;
  }

  .sexta-etapa-nossos-numeros-sub-2 {
    color: rgb(216, 216, 216);
    font-size: 10px;
    font-family: "Roboto";
    text-align: center;
    font-weight: 400;
    margin-bottom: 144px;
  }

  .sexta-etapa-nossos-numeros-sub-3 {
    color: rgb(216, 216, 216);
    font-size: 10px;
    font-family: "Roboto";
    text-align: center;
    font-weight: 400;
    margin-bottom: 124px;
  }

  /* ===============Fim estilo sexta Seção ======================== */

  .setima-section-img-caroseu {
    width: 38%;
  }

  .oitava-section-retangulo {
    text-align: center;
    width: 60%;
    height: 70%;
  }

  .oitava-section-retangulo-titulo {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 31px;
    color: #222222;
  }
}

@media (max-width: 767px) {
  .imagem-superior {
    padding-left: 0px;
    text-align: center;
  }

  .imagem-logo-superior {
    width: 200px;
  }

  .selo-seguranca-pedding {
    text-align: center;
    padding-left: 0px;
  }

  .estilo-titulo-primeiro {
    font-size: 35px;
    margin-top: 20px;
  }

  .estilo-titulo-primeiro-sub {
    font-size: 35px;
    margin-top: 10px;
  }

  .estilo-titulo-segundo {
    font-size: 15px;
  }

  .esconde-botao {
    display: none;
  }

  .mostra-botao {
    display: block;
  }

  .esconde-botao {
    display: none;
  }

  .mostra-botao {
    display: block;
  }

  .btn-segundo-mobile {
    padding: 5px;
    background: #222222;
    border-radius: 4px;
    border-color: #45b400;
    color: #45b400;
  }

  .img-painel-superior {
    padding-bottom: 44px;
  }

  .btn-primeiro-mobile {
    background: #45b400;
    border-radius: 4px;
    padding: 5px;
    border-color: #45b400;
  }

  .btn-segundo {
    width: 230px;
    font-size: 13px;
  }

  .btn-primeiro {
    width: 230px;
    font-size: 13px;
  }

  .img-selo-painel-superior-retangulo-verde {
    padding-top: 20px;
    width: 70%;
  }

  .img-selo-painel-superior-retangulo-qrcode {
    width: 70%;
  }

  .padding-retangulo-painel-superior {
    padding-top: 20px;
    text-align: -webkit-center;
  }

  .retangolo-selo {
    text-align: center;
    width: 35%;
    height: 100%;
    background: #ffffff;
    border-radius: 8px;
    margin-bottom: 23px;
  }

  /* ===============Fim estilo primeira Seção ======================== */
  .titulo-principal {
    font-size: 31px;
  }

  .retangulo-painel-superior {
    width: 100%;
    height: 72%;
    left: 10px;
  }

  .img-selo-retangulo-verde {
    width: 90%;
    padding-top: 20px;
  }

  .img-selo-retangulo-qrcode {
    width: 90%;
  }

  .titulo-principal-sub-1 {
    font-size: 16px;
  }

  .titulo-principal-sub-2 {
    font-size: 15px;
    line-height: 20px;
  }

  .btn-sub-topico {
    font-size: 20px;
  }

  .padding-mobile {
    margin-left: 50px;
  }

  .img-check-segunda-section {
    width: 20px;
    margin-top: -3px;
  }

  /* ===============Fim estilo segunda Seção ======================== */

  .terceira-section {
    padding-top: 60px;
    padding-left: 0px;
  }

  .imagem-terceira-position {
    text-align: -webkit-center;
  }

  .imagem-terceira-section {
    width: 160px;
  }

  .terceira-section-titulo {
    font-size: 28px;
    text-align: center;
  }

  .terceira-section-sub {
    padding-top: 20px;
    font-size: 18px;
    text-align: center;
  }

  /* ===============Fim estilo terceira Seção ======================== */

  .quarta-section-titulo {
    font-size: 30px;
    margin-bottom: 20px;
    text-align: center;
  }

  .quarta-section-titulo-sub {
    font-size: 17px;
    text-align: center;
  }

  .img-quarta-section {
    width: 219px;
    height: 268px;
  }

  /* ===============Fim estilo quarta Seção ======================== */
  .quinta-section-1 {
    padding-top: 100px;
    padding-bottom: 0px;
    padding-left: 0px;
  }

  .quinta-section {
    font-size: 18px;
  }

  .img-quinta-section-google {
    width: 200px;
  }

  .img-quinta-section-apple {
    width: 200px;
  }

  .img-quinta-section {
    padding-top: 12px;
    text-align: center;
    margin-left: 0px;
  }

  .img-quinta-section-1 {
    padding-top: 12px;
    text-align: center;
    margin-left: 0px;
  }

  /* ===============Fim estilo quinta Seção ======================== */
  .sexta-section-retangulo {
    position: relative;
    margin-top: 0px;
    width: 100%;
    padding-right: 0px;
    padding-left: 00px;
    margin-right: 0px;
    margin-left: 0px;
  }

  .sexta-section {
    background: linear-gradient(90.7deg,
        rgb(69, 180, 0) 1.76%,
        rgb(62, 163, 0) 96.3%);
    position: relative;
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: 0px;
    margin-left: 0px;
  }

  .sexta-section-titulo {
    font-family: "Archivo";
    font-weight: 600;
    font-size: 36px;
    color: #ffffff;
    padding-top: 35px;
    line-height: 40px;
    text-align: center;
  }

  .sexta-section-subtitulo {
    font-family: Roboto;
    font-weight: 400;
    font-size: 20px;
    color: rgb(224, 254, 214);
    padding-top: 26px;
    padding-bottom: 26px;
    text-align: center;
  }

  .sexta-section-1 {
    margin-top: 90px;
  }

  .sexta-etapa-nossos-numeros {
    font-weight: 700;
    font-size: 35px;
    text-align: center;
    color: rgb(69, 180, 0);
    margin-top: 100px;
  }

  .sexta-section-retangulo-preto {
    background: linear-gradient(124.16deg, #222222 18.46%, #131414 83.41%);
  }

  .sexta-etapa-nossos-numeros-1 {
    font-size: 48px;
    color: rgb(255, 255, 255);
    font-weight: 900;
    text-align: center;
  }

  .sexta-etapa-nossos-numeros-sub {
    color: rgb(216, 216, 216);
    font-size: 10px;
    font-family: "Roboto";
    text-align: center;
    font-weight: 400;
    margin-bottom: 104px;
  }

  .sexta-etapa-nossos-numeros-2 {
    font-size: 48px;
    color: rgb(255, 255, 255);
    font-weight: 900;
    text-align: center;
  }

  .sexta-etapa-nossos-numeros-sub-2 {
    color: rgb(216, 216, 216);
    font-size: 10px;
    font-family: "Roboto";
    text-align: center;
    font-weight: 400;
    margin-bottom: 104px;
  }

  .sexta-etapa-nossos-numeros-3 {
    font-size: 48px;
    color: rgb(255, 255, 255);
    font-weight: 900;
    text-align: center;
  }

  .sexta-etapa-nossos-numeros-sub-3 {
    color: rgb(216, 216, 216);
    font-size: 10px;
    font-family: "Roboto";
    text-align: center;
    font-weight: 400;
    margin-bottom: 90px;
  }

  /* ===============Fim estilo sexta Seção ======================== */
  .setima-section {
    background: linear-gradient(90.7deg, #45b400 1.76%, #3ea300 96.3%);
  }

  .setima-section-titulo {
    font-weight: 600;
    font-size: 32px;
    color: rgb(255, 255, 255);
    text-align: center;
    padding-top: 81px;
  }

  .setima-section-img-caroseu {
    border-radius: 4px;
    background: rgb(255, 255, 255);
    margin-top: 16px;
    margin-bottom: 60px;
    margin-right: 20px;
    width: 90%;
  }

  /* ===============Fim estilo setima Seção ======================== */
  .oitava-section {
    background: #f4f4f4;
    padding-left: 5%;
    padding-top: 5%;
    padding-right: 5%;
    padding-bottom: 30%;
    border-radius: 4px;
  }

  .oitava-section-titulo {
    font-weight: 500;
    font-size: 40px;
    padding-bottom: 0px;
    text-align: center;
    padding-top: 30px;
  }

  .oitava-section-retangulo-titulo {
    font-weight: 500;
    font-size: 28px;
  }

  .oitava-section-card {
    background: #f4f4f4;
    border-radius: 0;
    padding: 0px;
    text-align: center;
  }

  .oitava-section-retangulo {
    text-align: -webkit-center;
    width: 130px;
    height: 100%;
    background: rgb(255, 255, 255);
    border-radius: 8px;
    box-shadow: rgb(0 0 0 / 10%) 3px 3px 50px;
  }

  .oitava-section-retangulo-logo {
    width: 70%;
    padding-top: 8px;
  }

  .oitava-section-retangulo-qrcode {
    width: 70%;
    padding-bottom: 8px;
  }

  .oitava-section-retangulo-subtitulo {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 0px;
    padding-bottom: 39px;
  }

  .oitava-section-retangulo-subtitulo-1 {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 0px;
    padding-left: 0px;
    text-align: center;
    padding-bottom: 40px;
  }

  .estilo-preco-pedido {
    font-weight: 700;
    font-size: 20px;
    text-align: right;
  }

  .estilo-pedido {
    font-weight: 400;
    font-size: 20px;
    color: #a3a3a3;
  }

  .bottom-final {
    box-sizing: border-box;
    width: 47px;
    height: 45.32px;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(186, 186, 186);
  }

  .cor-botao {
    color: #000000;
  }

  .esconde-linha {
    display: none;
  }

  .quinta-section-1 {
    padding-top: 60px;
  }

  .setima-section-img2-caroseu {
    margin-top: -28px;
    margin-left: -9px;
  }
}
</style>
